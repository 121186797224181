<template>
  <div>
    <SettingsTitle title="Billing & Plans"/>
    <AccountLine title="Current Plan" :value="userAppData.plan ? userAppData.plan : ' '" />
    <AccountLine title="Email" :value="user_meta.email" />
    <div class="flex flex-wrap pb-16">
      <PlanBlock
        v-for="(plan, index) in billingPlans"
        :key="`${plan.id}${index}`"
        :plan="plan"
        :selectedPlan="selectedPlan"
        class="mr-8 cursor-pointer"
        @setPlan="setPlan"
        @btnAction="btnAction"
      >
        <template v-slot:icon>
          <component :is="plan.icon"></component>
        </template>
        <template 
          v-if="plan.id == 2"
          v-slot:apply
        >
          <p class="flex items-center border-t border-grey-darker-100 my-4 py-4">
            <span class="w-9/12 pl-2 yexy-base base-font-gray-200">Free for journalists and academic researchers</span>
            <router-link 
              to="/settings/billing-plans/request"
              class="w-3/12 border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-mono font-medium text-center"
            >
              Apply
            </router-link>
          </p>
        </template>
      </PlanBlock>
    </div>
  </div>
</template>

<script>  
  import SettingsTitle from '@/components/settings/SettingsTitle'
  import AccountLine from '@/components/settings/AccountLine'
  import PlanBlock from '@/components/settings/PlanBlock'
  import triangleInShield from '@/components/icons/triangle-in-shield'
  import circlesOnCircle from '@/components/icons/circles-on-circle'
  import pointInCircles from '@/components/icons/point-in-circles'
  import { mapGetters } from 'vuex'
  import { billingPlans } from '@/helpers/constants'

  export default {
    components: { 
      SettingsTitle, 
      AccountLine,
      PlanBlock,
      triangleInShield,
      circlesOnCircle,
      pointInCircles
    },
    data(){
      return{
        billingPlans: billingPlans,
        selectedPlan: ''
      }
    },
    computed:{
      ...mapGetters({
        userAppData: 'general/userAppData',
        user_meta: 'general/user_meta'
      })
    },
    methods: {
      setPlan(name){
        this.selectedPlan = name
      },
      btnAction(plan){
        console.log(plan)
      }
    },
    mounted(){
      this.setPlan(this.userAppData.plan)
    }
  }
</script>

<style lang="scss" scoped>

</style>