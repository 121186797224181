<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.588 2.88898C16.02 4.32103 16.02 6.64285 14.588 8.0749C13.1559 9.50695 10.8341 9.50695 9.40204 8.0749C7.96999 6.64285 7.96999 4.32103 9.40204 2.88898C10.8341 1.45693 13.1559 1.45693 14.588 2.88898" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.2247 14.3833C22.6544 15.813 22.6544 18.131 21.2247 19.5608C19.795 20.9905 17.477 20.9905 16.0473 19.5608C14.6175 18.131 14.6175 15.813 16.0473 14.3833C17.477 12.9536 19.795 12.9536 21.2247 14.3833" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.95273 14.3833C9.38244 15.813 9.38244 18.131 7.95273 19.5608C6.52302 20.9905 4.205 20.9905 2.77529 19.5608C1.34558 18.131 1.34558 15.813 2.77529 14.3833C4.205 12.9536 6.52302 12.9536 7.95273 14.3833" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.4186 7.72747C20.4112 10.7201 20.4112 15.572 17.4186 18.5646C14.426 21.5572 9.57405 21.5572 6.58148 18.5646C3.58889 15.572 3.58889 10.72 6.58148 7.72747C9.57407 4.73488 14.426 4.73488 17.4186 7.72747" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>