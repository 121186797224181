<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.8099 1.93994L3.18994 16.8599" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.8099 16.8599L12.1899 1.93994" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.6099 17.2H3.38989" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 7.95896V16.042C21 16.757 20.619 17.417 20 17.774L13 21.815C12.381 22.172 11.619 22.172 11 21.815L4 17.774C3.381 17.417 3 16.756 3 16.042V7.95896C3 7.24396 3.381 6.58396 4 6.22696L11 2.18596C11.619 1.82896 12.381 1.82896 13 2.18596L20 6.22696C20.619 6.58396 21 7.24396 21 7.95896V7.95896Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>