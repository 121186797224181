var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 mt-6 w-80 border-grey-darker-100 border rounded",class:{
    'purple-light-b1-100': _vm.plan.planName == _vm.selectedPlan
  },on:{"click":_vm.setPlan}},[_c('div',{staticClass:"w-full mb-1 mt-10"},[_vm._t("icon")],2),_c('p',{staticClass:"text-xl pb-8 border-b",class:{
      'border-grey-darker-100': _vm.plan.planName != _vm.selectedPlan,
      'border-purple-light': _vm.plan.planName == _vm.selectedPlan,
      'purple-text': _vm.plan.planName == _vm.selectedPlan,
      'base-font-gray-200': _vm.plan.planName != _vm.selectedPlan
    }},[_vm._v(" "+_vm._s(_vm.plan.title)+" ")]),_c('div',{staticClass:"h-44 border-b border-grey-darker-100 mb-3.5"},[_c('p',{staticClass:"font-bold base-font-gray-200 text-2xl mt-6"},[_vm._v(_vm._s(_vm.plan.priceText))]),(_vm.plan.btnText)?[(_vm.plan.btnLink)?_c('router-link',{staticClass:"font-monoDemi inline-block text-xs font-grey-darker-500 leadint-none purple-bg purple-light whitespace-nowrap rounded-2xl px-5 py-2 mr-4 mt-4",attrs:{"to":_vm.plan.btnLink}},[_vm._v(" "+_vm._s(_vm.plan.btnText)+" ")]):_c('button',{staticClass:"font-monoDemi inline-block text-xs font-grey-darker-500 leadint-none purple-bg purple-light whitespace-nowrap rounded-2xl px-5 py-2 mr-4 mt-4",on:{"click":_vm.btnAction}},[_vm._v(" "+_vm._s(_vm.plan.btnText)+" ")])]:_vm._e(),_vm._t("apply")],2),_vm._l((_vm.plan.list),function(item){return _c('p',{key:item,staticClass:"base-font-gray-200 text-lg py-3.5"},[_vm._v(" "+_vm._s(item)+" ")])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }