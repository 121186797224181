<template>
  <div 
    class="px-4 mt-6 w-80 border-grey-darker-100 border rounded"
    :class="{
      'purple-light-b1-100': plan.planName == selectedPlan
    }"
    @click="setPlan"
  >
    <div class="w-full mb-1 mt-10">
      <slot name="icon" />
    </div>
    <p 
      class="text-xl pb-8 border-b"
      :class="{
        'border-grey-darker-100': plan.planName != selectedPlan,
        'border-purple-light': plan.planName == selectedPlan,
        'purple-text': plan.planName == selectedPlan,
        'base-font-gray-200': plan.planName != selectedPlan
      }"
    >
      {{ plan.title }}
    </p>
    <div class="h-44 border-b border-grey-darker-100 mb-3.5">
      <p class="font-bold base-font-gray-200 text-2xl mt-6">{{ plan.priceText }}</p>

      <template v-if="plan.btnText">
        <router-link 
          v-if="plan.btnLink"
          :to="plan.btnLink"
          class="font-monoDemi inline-block text-xs font-grey-darker-500 leadint-none purple-bg purple-light whitespace-nowrap rounded-2xl px-5 py-2 mr-4 mt-4"
        >
          {{ plan.btnText }}
        </router-link>
        <button 
          v-else
          @click="btnAction"
          class="font-monoDemi inline-block text-xs font-grey-darker-500 leadint-none purple-bg purple-light whitespace-nowrap rounded-2xl px-5 py-2 mr-4 mt-4"
        >
          {{ plan.btnText }}
        </button>

      </template>

      <slot name="apply" />
    </div>
    
    <p
      v-for="item in plan.list"
      :key="item"
      class="base-font-gray-200 text-lg py-3.5"
    >
      {{ item }}
    </p>
  </div>
</template>

<script>
  export default {
    props: [ 'plan', 'selectedPlan' ],
    methods: {
      setPlan(){
        this.$emit('setPlan', this.plan.planName)
      },
      btnAction(){
        this.$emit('btnAction', this.plan)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>